import React from "react";
import { postData } from "../../Services/Service";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function Form() {
  let history = useNavigate(); //To navigate between pages

  const sendEmail = (data, e) => {
    e.preventDefault();

    const templateParams = {
      from_name: `${data.firstName} ${data.lastName}`,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      street: data.street,
      city: data.city,
      postalCode: data.postalCode,
      reply_to: data.email,
      from_email: data.email,
    };

    emailjs
      .send(
        "service_kq8hf5c",
        "template_r716i8k",
        templateParams,
        "FuqNV8mUCsn7GdGTO"
      )
      .then(
        (result) => {
          console.log("Success:", result);
          if (result.text === "OK") {
            alert("Success");
            reset();
            history("/thankyou");
          } else {
            alert("Failed");
          }
        },
        (error) => {
          console.log("Error details:", error);
          alert("Sorry we cannot submit your message please reach via call");
        }
      );
  };

  const {
    register,
    handleSubmit,
    getValues,
    reset,

    formState: { errors },
  } = useForm();

  return (
    <div>
      <form onSubmit={handleSubmit(sendEmail)} className="w-75 mx-auto">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="email"
                name="email"
                class="form-control form-control-lg form-control-a"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",
                })}
              />
            </div>
          </div>
          <div>
            <p className="text-success">{errors.email?.message}</p>
          </div>
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="text"
                name="firstName"
                class="form-control form-control-lg form-control-a"
                placeholder="First Name"
                {...register("firstName", {
                  required: "First name is required",
                })}
              />
            </div>
            <div>
              <p className="mt-3 text-success">{errors.firstName?.message}</p>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                name="lastName"
                type="text"
                class="form-control form-control-lg form-control-a"
                placeholder="Last Name"
                {...register("lastName", {
                  required: "Last Name is required",
                })}
              />
            </div>
            <div>
              <p className="mt-3 text-success">{errors.lastName?.message}</p>
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="text"
                name="phone"
                class="form-control form-control-lg form-control-a"
                placeholder="Phone"
                {...register("phone", {
                  required: "Phone Number is required",
                  minLength: {
                    value: 10,
                    message: "Atleast 10 digits are required",
                  },
                  maxLength: {
                    value: 13,
                    message: "Cannot exceed 13 digits ",
                  },
                })}
              />
            </div>
          </div>
          <div>
            <p className="text-success">{errors.phone?.message}</p>
          </div>
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="text"
                name="street"
                class="form-control form-control-lg form-control-a"
                placeholder="Street"
                {...register("street", {
                  required: "Street name is required",
                })}
              />
            </div>
          </div>
          <div>
            <p className="text-success">{errors.street?.message}</p>
          </div>

          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="text"
                name="city"
                class="form-control form-control-lg form-control-a"
                placeholder="City"
                {...register("city", {
                  required: "City name is required",
                })}
              />
            </div>
          </div>
          <div>
            <p className="text-success">{errors.city?.message}</p>
          </div>
          <div class="col-md-12 mb-3">
            <div class="form-group">
              <input
                type="text"
                name="postalCode"
                class="form-control form-control-lg form-control-a"
                placeholder="Postal Code"
                {...register("postalCode", {
                  required: "Postal code is required",
                })}
              />
            </div>
          </div>
          <div>
            <p className="text-success">{errors.postalCode?.message}</p>
          </div>

          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-lg btn-success">
              SCHEDULE YOUR APPOINTMENT TODAY
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
