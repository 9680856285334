import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogPost from "./BlogPost";
import "./Blog.css";

function Blog({ defaultCategory = "" }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [allCategories, setAllCategories] = useState([]);
  const [viewMode, setViewMode] = useState("grid"); // grid or list view

  // Update selectedCategory when defaultCategory prop changes
  useEffect(() => {
    setSelectedCategory(defaultCategory);
  }, [defaultCategory]);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    setLoading(true);
    setError(null);

    try {
      // Direct fetch from RSS feed URL
      const response = await axios.get(
        "https://www.simplifyingthemarket.com/en/feed/?a=500070-ab14bdd7fe835090a3b233776113bb76",
        {
          // Adding headers to help with CORS issues
          headers: {
            Accept: "application/rss+xml, application/xml, text/xml",
          },
        }
      );

      // Process the XML data
      processBlogData(response.data);
    } catch (err) {
      console.error("Error fetching blog posts:", err);
      setError(
        "Failed to load blog posts. This may be due to CORS restrictions. Please check console for details."
      );
      setLoading(false);
    }
  };

  const processBlogData = (xmlData) => {
    try {
      // Parse the XML content
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");

      // Extract the blog posts
      const items = xmlDoc.getElementsByTagName("item");
      const blogPosts = [];
      const categories = new Set();

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // Extract the blog post data
        const title = item.getElementsByTagName("title")[0]?.textContent || "";
        const link = item.getElementsByTagName("link")[0]?.textContent || "";
        const pubDate =
          item.getElementsByTagName("pubDate")[0]?.textContent || "";
        const creator =
          item.getElementsByTagName("dc:creator")[0]?.textContent || "";

        // Get the content from content:encoded tag (full content)
        const contentEncoded =
          item.getElementsByTagName("content:encoded")[0]?.textContent || "";

        // Get description (shorter preview)
        const description =
          item.getElementsByTagName("description")[0]?.textContent || "";

        // Get categories
        const postCategories = [];
        const categoryElements = item.getElementsByTagName("category");
        for (let j = 0; j < categoryElements.length; j++) {
          const category = categoryElements[j].textContent;
          postCategories.push(category);
          categories.add(category);
        }

        // Add the blog post to the array
        blogPosts.push({
          title,
          link,
          pubDate,
          creator,
          contentEncoded,
          description,
          categories: postCategories,
        });
      }

      setPosts(blogPosts);
      setAllCategories(Array.from(categories).sort());
      setLoading(false);
    } catch (err) {
      console.error("Error processing XML data:", err);
      setError("Failed to process blog data. Please try again later.");
      setLoading(false);
    }
  };

  // Helper function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Filter posts based on search term and selected category
  const filteredPosts = posts.filter((post) => {
    const matchesSearch =
      searchTerm === "" ||
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.contentEncoded.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory =
      selectedCategory === "" || post.categories.includes(selectedCategory);

    return matchesSearch && matchesCategory;
  });

  const getPageTitle = () => {
    if (selectedCategory) {
      return `${selectedCategory} Articles`;
    }
    return "Latest Real Estate Insights";
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "list" : "grid");
  };

  if (loading) {
    return (
      <div className="blog-container py-5">
        <div className="container">
          <div className="text-center">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <h2 className="section-heading">Real Estate Insights</h2>
              </div>
            </div>
            <div className="my-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <p className="mt-3">Loading blog posts...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="blog-container py-5">
        <div className="container">
          <div className="text-center">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8">
                <h2 className="section-heading">Real Estate Insights</h2>
              </div>
            </div>
            <div className="alert alert-danger my-5" role="alert">
              {error}
              <div className="mt-3">
                <button className="btn btn-primary" onClick={fetchBlogPosts}>
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-container py-5">
      <div className="container">
        <div className="text-center mb-5">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <h2 className="section-heading">{getPageTitle()}</h2>
              <p className="text-muted lead">
                Stay informed with the latest trends in the housing market
              </p>
            </div>
          </div>
        </div>

        {/* Filter and search options */}
        <div className="blog-controls bg-white p-3 rounded shadow-sm mb-4">
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="input-group">
                <span className="input-group-text bg-white border-end-0">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="text"
                  className="form-control border-start-0"
                  placeholder="Search posts..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  aria-label="Search posts"
                />
                {searchTerm && (
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setSearchTerm("")}
                    aria-label="Clear search"
                  >
                    <i className="bi bi-x-lg"></i>
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="input-group">
                <span className="input-group-text bg-white border-end-0">
                  <i className="bi bi-filter"></i>
                </span>
                <select
                  className="form-select border-start-0"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  aria-label="Filter by category"
                >
                  <option value="">All Categories</option>
                  {allCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-md-end">
              <div className="view-toggle btn-group">
                <button
                  type="button"
                  className={`btn ${
                    viewMode === "grid" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => setViewMode("grid")}
                  aria-label="Grid view"
                >
                  <i className="bi bi-grid-3x3-gap-fill"></i>
                </button>
                <button
                  type="button"
                  className={`btn ${
                    viewMode === "list" ? "btn-primary" : "btn-outline-primary"
                  }`}
                  onClick={() => setViewMode("list")}
                  aria-label="List view"
                >
                  <i className="bi bi-list-ul"></i>
                </button>
              </div>
              <div className="ms-3 results-count">
                <span className="badge bg-secondary">
                  {filteredPosts.length} posts
                </span>
              </div>
            </div>
          </div>
        </div>

        {filteredPosts.length === 0 ? (
          <div className="alert alert-info text-center p-4">
            <i className="bi bi-info-circle-fill fs-3 d-block mb-3"></i>
            <h5>No posts match your current filters</h5>
            <p>
              Try adjusting your search criteria or select a different category.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => {
                setSearchTerm("");
                setSelectedCategory("");
              }}
            >
              Reset Filters
            </button>
          </div>
        ) : (
          <div className="row">
            {filteredPosts.map((post, index) => (
              <div
                className={
                  viewMode === "grid" ? "col-lg-4 col-md-6 mb-4" : "col-12 mb-4"
                }
                key={index}
              >
                <BlogPost
                  post={post}
                  formatDate={formatDate}
                  viewMode={viewMode}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
