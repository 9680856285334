import React, { useState } from "react";

function BlogPost({ post, formatDate, viewMode = "grid" }) {
  const [showFullContent, setShowFullContent] = useState(false);

  // Function to create markup from HTML string
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  // Helper to extract first image from content if available
  const extractFirstImage = (content) => {
    const imgRegex = /<img[^>]+src="([^">]+)"/;
    const match = content.match(imgRegex);
    return match ? match[1] : null;
  };

  // Get the first image from the content
  const firstImage = extractFirstImage(post.contentEncoded);

  // Remove images from preview content to avoid duplication
  const getImageFreePreview = () => {
    // Get first paragraph
    let preview = post.contentEncoded.split("</p>")[0] + "</p>";

    // Remove any image tags from the preview
    preview = preview.replace(/<img[^>]+>/g, "");

    return preview;
  };

  const truncateTitle = (title, maxLength = viewMode === "grid" ? 65 : 100) => {
    if (title.length <= maxLength) return title;
    return title.substring(0, maxLength) + "...";
  };

  // Render different layouts based on viewMode
  if (viewMode === "list") {
    return (
      <div className="blog-card card shadow hover-card list-view">
        <div className="row g-0">
          {firstImage && !showFullContent && (
            <div className="col-md-4">
              <div className="blog-image-container h-100">
                <img
                  src={firstImage}
                  className="blog-image rounded-start h-100"
                  alt={post.title}
                  loading="lazy"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                  }}
                />
                <div className="blog-image-overlay">
                  <div className="category-badge-container">
                    {post.categories.slice(0, 1).map((category, catIndex) => (
                      <span key={catIndex} className="category-highlight">
                        {category}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={`col-md-${firstImage && !showFullContent ? "8" : "12"}`}
          >
            <div className="card-body d-flex flex-column h-100">
              <h5 className="card-title" title={post.title}>
                {truncateTitle(post.title)}
              </h5>

              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="card-text text-muted mb-0">
                  <small>
                    <i className="bi bi-calendar-event me-1"></i>
                    {formatDate(post.pubDate)}
                  </small>
                </p>
                <p className="card-text text-muted mb-0">
                  <small>
                    <i className="bi bi-person me-1"></i>
                    {post.creator}
                  </small>
                </p>
              </div>

              <div className="mb-3 category-container">
                {post.categories.map((category, catIndex) => (
                  <span key={catIndex} className="category-tag me-1 mb-1">
                    {category}
                  </span>
                ))}
              </div>

              {/* Show either preview or full content based on state */}
              <div className="blog-content mb-3">
                {showFullContent ? (
                  <div
                    className="full-content"
                    dangerouslySetInnerHTML={createMarkup(post.contentEncoded)}
                  />
                ) : (
                  <div
                    className="preview-content"
                    dangerouslySetInnerHTML={createMarkup(
                      getImageFreePreview()
                    )}
                  />
                )}
              </div>

              <div className="mt-auto d-flex justify-content-between">
                <a
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary btn-sm"
                >
                  View Original
                </a>

                <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={() => setShowFullContent(!showFullContent)}
                >
                  {showFullContent ? "Show Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Default grid view
  return (
    <div className="blog-card card h-100 shadow hover-card">
      {firstImage && !showFullContent && (
        <div className="blog-image-container">
          <img
            src={firstImage}
            className="card-img-top blog-image"
            alt={post.title}
            loading="lazy"
            style={{
              height: "200px",
              objectFit: "cover",
              width: "100%",
              borderTopLeftRadius: "0.375rem",
              borderTopRightRadius: "0.375rem",
            }}
          />
          <div className="blog-image-overlay">
            <div className="category-badge-container">
              {post.categories.slice(0, 1).map((category, catIndex) => (
                <span key={catIndex} className="category-highlight">
                  {category}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="card-body d-flex flex-column">
        <h5 className="card-title" title={post.title}>
          {truncateTitle(post.title)}
        </h5>

        <p className="card-text text-muted mb-3">
          <small>
            <i className="bi bi-calendar-event me-1"></i>
            {formatDate(post.pubDate)} &nbsp;|&nbsp;
            <i className="bi bi-person me-1"></i>
            {post.creator}
          </small>
        </p>

        <div className="mb-3 category-container">
          {post.categories.map((category, catIndex) => (
            <span key={catIndex} className="category-tag me-1 mb-1">
              {category}
            </span>
          ))}
        </div>

        {/* Show either preview or full content based on state */}
        <div className="blog-content mb-3">
          {showFullContent ? (
            <div
              className="full-content"
              dangerouslySetInnerHTML={createMarkup(post.contentEncoded)}
            />
          ) : (
            <div
              className="preview-content"
              dangerouslySetInnerHTML={createMarkup(getImageFreePreview())}
            />
          )}
        </div>

        <div className="card-footer bg-white border-top-0 d-flex justify-content-between mt-auto">
          <a
            href={post.link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-sm"
          >
            View Original
          </a>

          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={() => setShowFullContent(!showFullContent)}
          >
            {showFullContent ? "Show Less" : "Read More"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
