import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="fixed-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <div class="" id="navbarTogglerDemo01">
            <ul class="custom-nav d-flex mr-auto ">
              <li class="nav-item me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                <span>
                  <strong className="ms-2">
                    <a href="mailto:info@brepm.com">info@brepm.com</a>
                  </strong>
                </span>
              </li>
              <li class="nav-item me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                <strong className="ms-2">
                  <a href="tel:6234714451">623-471-4451</a>
                </strong>
              </li>
              <li className="nav-item nav-hide-display me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-person-badge"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                </svg>
                <strong className="ms-2">
                  <a
                    href="https://www.rentcafe.com/residentservices/apartmentsforrent/userlogin.aspx"
                    target="_blank"
                  >
                    Tenant Login
                  </a>
                </strong>
              </li>
              <li className="nav-item nav-hide-display me-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                  />
                </svg>
                <strong className="ms-2">
                  <a
                    href="https://100091919.breeze.cafe/owner/content/login#/appaccess/login"
                    target="_blank"
                  >
                    Owner Login
                  </a>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav class="py-2 navbar navbar-default navbar-trans navbar-expand-lg">
        <div class="container">
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarDefault"
            aria-controls="navbarDefault"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <Link class="navbar-brand text-brand" to="/">
            <img
              className="navbar-logo"
              src="images/breakthroughLogo.png"
              alt=""
            />
          </Link>

          <div
            class="navbar-collapse collapse justify-content-end"
            id="navbarDefault"
          >
            <ul class="navbar-nav me-5">
              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/about">
                  About
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/contact">
                  Contact
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" onClick={scrollToTop} to="/trench-life">
                  Trench Life
                </Link>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="blogDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Blog
                </a>
                <div class="dropdown-menu">
                  <Link onClick={scrollToTop} class="dropdown-item" to="/blog">
                    All Articles
                  </Link>
                  <Link
                    onClick={scrollToTop}
                    class="dropdown-item"
                    to="/blog/market-updates"
                  >
                    Market Updates
                  </Link>
                  <Link
                    onClick={scrollToTop}
                    class="dropdown-item"
                    to="/blog/buying-tips"
                  >
                    Buying Tips
                  </Link>
                  <Link
                    onClick={scrollToTop}
                    class="dropdown-item"
                    to="/blog/selling-tips"
                  >
                    Selling Tips
                  </Link>
                </div>
              </li>

              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  More
                </a>
                <div class="dropdown-menu">
                  <Link onClick={scrollToTop} class="dropdown-item" to="/store">
                    Store
                  </Link>
                  <Link
                    onClick={scrollToTop}
                    class="dropdown-item"
                    to="/home-value"
                  >
                    Your Homes Value?
                  </Link>
                  <a
                    href="https://www.rentcafe.com/residentservices/apartmentsforrent/userlogin.aspx"
                    target="_blank"
                    class="dropdown-item extra-nav-item"
                  >
                    Tenant Login
                  </a>
                  <a
                    href="https://100091919.breeze.cafe/owner/content/login#/appaccess/login"
                    target="_blank"
                    class="dropdown-item extra-nav-item"
                  >
                    Owner Login
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
